<template>
	<!-- Add Voter Modal -->
		<div class="modal fade show" id="modal-default" tabindex="-1" style="display: block;" aria-modal="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class='modal-content' v-if='add_running'>
					<div class="modal-body">
						<h5 class='text-info'>Updating Company</h5>
						Updating company in the system...
					</div>
				</div>
				<div class="modal-content" v-if='!add_running'>
					<div class="modal-header">
						<h5 class="modal-title">Edit Company</h5><i class="modal-close zwicon-close pull-right display-4" @click='close'></i>
					</div>
					<div class="modal-body">
						<div class='row'>
							<div class='col-md-12'>
								<div class="form-group mb-0">
									<label>Name <sup class='text-danger'>*</sup></label>
									<input :class="{'is-invalid': get_error('name').length > 0}" class="form-control" v-model='user.name' placeholder='Ex. Drilling Inc.'/>
								</div>
								<span v-show="get_error('name').length > 0" class="text-danger">{{ get_error('name') }}</span>
							</div>							
						</div>
						<div class='row mt-3'>
							<div class='col-md-12'>
								<div class="form-group mb-0">
									<label>Address</label>
									<input :class="{'is-invalid': get_error('address').length > 0}" class="form-control" v-model='user.address' placeholder='Ex. 123 Address Rd.'/>
								</div>
								<span v-show="get_error('address').length > 0" class="text-danger">{{ get_error('address') }}</span>
							</div>
						</div>
						<div class='row mt-3'>
							<div class='col-md-6'>
								<div class="form-group mb-0">
									<label>City</label>
									<input :class="{'is-invalid': get_error('city').length > 0}" class="form-control" v-model='user.city' placeholder='Calgary'/>
								</div>
								<span v-show="get_error('city').length > 0" class="text-danger">{{ get_error('city') }}</span>
							</div>
							<div class='col-md-6'>
								<div class="form-group mb-0">
									<label>Region</label>
									<input :class="{'is-invalid': get_error('region').length > 0}" class="form-control" v-model='user.region' placeholder='AB'/>
								</div>
								<span v-show="get_error('region').length > 0" class="text-danger">{{ get_error('region') }}</span>
							</div>
						</div>
                        <div class='row mt-3'>
							<div class='col-md-6'>
								<div class="form-group mb-0">
									<label>Postal Code</label>
									<input :class="{'is-invalid': get_error('postal_code').length > 0}" class="form-control" v-model='user.postal_code' placeholder='A2V 1W3'/>
								</div>
								<span v-show="get_error('postal_code').length > 0" class="text-danger">{{ get_error('postal_code') }}</span>
							</div>
							<div class='col-md-6'>
								<div class="form-group mb-0">
									<label>Phone #</label>
									<input :class="{'is-invalid': get_error('phone').length > 0}" class="form-control" v-model='user.phone' placeholder='555-123-1234'/>
								</div>
								<span v-show="get_error('phone').length > 0" class="text-danger">{{ get_error('phone') }}</span>
							</div>
						</div>
						<div class='row mt-3'>
							<div class='col-md-12'>
								<div class="form-group mb-0">
									<label>Website</label>
									<input :class="{'is-invalid': get_error('website').length > 0}" class="form-control" v-model='user.website' placeholder='www.website.com'/>
								</div>
								<span v-show="get_error('website').length > 0" class="text-danger">{{ get_error('website') }}</span>
							</div>
						</div>
                        <div class='row mt-3'>
							<div class='col-md-12'>
								<div class="form-group mb-0">
									<label>Logo URL</label>
									<input :class="{'is-invalid': get_error('logo_url').length > 0}" class="form-control" v-model='user.logo_url' placeholder='www.logo-url.com/image.png'/>
								</div>
								<span v-show="get_error('logo_url').length > 0" class="text-danger">{{ get_error('logo_url') }}</span>
							</div>
						</div>
					</div>
					<ErrorMessage :message='error' v-if='error.length > 0' />
					<div class="modal-footer">
						<button type="button" class="btn btn-danger" @click='action'>Edit</button>
					</div>
				</div>
			</div>
		</div>
		<!-- End Add Voter Modal -->
</template>

<script>

	import store from "@/store";

	import PermissionService from "@/common/permission.service";

	import { EDIT_COMPANY } from "@/store/actions.type";

	import ErrorMessage from "@/components/General/ErrorMessage";

	export default {
		name: 'AddUser',
		components: {
			ErrorMessage
		},
		props: {
            item: {
				type: Object,
				required: true
			}
        },
		data(){
			return {
				error: '',
				add_running: false,
				user: {
                    company_id: '',
					name: '',
					address: '',
					city: '',
					region: '',
                    postal_code: '',
                    phone: '',
					website: '',
					logo_url: '',
                    fax: ''
				},
				errors: {
					name: '',
					address: '',
					city: '',
					region: '',
                    postal_code: '',
					website: '',
					logo_url: ''
				}
			};
		},
        mounted(){
            this.user = {
                    company_id: this.item.company_id,
					name: this.item.name,
					address: this.item.address,
					city: this.item.city,
					region: this.item.province,
                    postal_code: this.item.postal_code,
                    phone: this.item.phone,
                    fax: this.item.fax,
					website: this.item.website,
					logo_url: this.item.logo_url
				}
        },
		methods: {
			getPermission(category, permission){
				return PermissionService.getPermission(category, permission);
			},
			get_error(key){
				if(this.errors[key] !== undefined) return this.errors[key];
				return '';
			},
			close(){
				if(this.add_running) return;
				this.$emit('close');
			},
			action(){
				this.validate_add_user();
				
			},
			validate_add_user(){
				this.errors  = {
					name: '',
					address: '',
					city: '',
					region: '',
                    postal_code: '',
					website: '',
					logo_url: ''
				};
				if(this.user.name.length < 3){
					this.errors.name = "Please enter company name.";
					return;
				}
				this.add_user();
			},
			add_user(){
				this.error = '';
				if(this.add_running) return;
				this.add_running = true;
				store.dispatch(EDIT_COMPANY, this.user).then(() => {
					this.$emit('updated');
				}).catch(message => {
					if(message !== undefined) this.error = message;
					else this.error = "An error occured while attempting to create user.";
					this.add_running = false;
				}).finally(() => {
				});
			}
		}
	}
</script>