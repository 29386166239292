<template>
	<ul class="pagination justify-content-end">

		<li class="page-item pagination-prev" @click="backPage();" :class="hasBack()"><a class="page-link"><i class="zwicon-arrow-left"></i></a></li>

		<li class='page-link' v-if='this.current_page > 5'><span class='paginate-ellipsis'>...</span></li>

		<li
			v-for="page in page_list"
			:data-test="`page-link-${page}`"
			:key="page"
			:class="paginationClass(page)"
			@click="changePage(page);"
		>
			<a class="page-link">{{page}}</a>
		</li>

		<li class='page-link' v-if='this.current_page < (pages.length - 4)'><span class='paginate-ellipsis'>...</span></li>

		<li class="page-item pagination-next" @click="nextPage();" :class="hasNext()"><a class="page-link"><i class="zwicon-arrow-right"></i></a></li>

	</ul>
</template>

<script>
	export default {
		name: "Pagination",
		components: {},
		props: {
			pages: {
				type: Array,
				required: true
			},
			current_page: {
				type: Number,
				required: true
			}
		},
		computed: {
			page_list(){
				return this.pages.filter(page => this.showPage(page));
			}
		},	
		methods: {
			showPage(page){
				//Only show
				if(Math.abs(page - (this.current_page+1)) < 5) return true;
				else return false;
			},
			hasBack(){
				return {
					"disabled": (this.current_page+1) <= 1
				}
			},
			backPage(){
				if((this.current_page+1) <= 1) return false;
				this.changePage((this.current_page+1)-1);
			},
			hasNext(){
				return {
					"disabled": (this.current_page+1) >= this.pages.length
				}
			},
			nextPage(){
				if((this.current_page+1) >= this.pages.length) return false;
				this.changePage((this.current_page+1)+1);
			},
			changePage(goToPage) {
				if (goToPage === (this.current_page+1)) return;
				this.$emit("set_page", (goToPage-1));
			},
			paginationClass(page) {
				return {
					"page-item": true,
					"clickable": true,
					"active": (this.current_page+1) === page
				};
			}
		}
	}
</script>