<template>
	<div>

        <AddItem v-if='add_item_modal' @close='add_item_modal = false;' @added="item_added" />
        <EditItem v-if='edit_item_modal' @close='edit_item_modal = false;' @updated="item_edited" :item="action_item" />

        <div class="container-fluid">
            <div class="card mt-3">

                <div class="card-body">
                    <h4 class="card-title">Companies</h4>
                    <div class="actions">
                    <button class="btn btn-primary btn--icon-text ml-3 mr-3" v-tooltip="'Add a Item'" @click='add_item()'><i class="zwicon-plus"></i> Add Company</button>
                                    
                    </div>
                    
                    <div class="table-responsive data-table table-sm table-striped">
                        <div id="data-table_wrapper" class="dataTables_wrapper no-footer">
                            <table id="data-table" class="table dataTable no-footer" role="grid" aria-describedby="data-table_info">
                            <thead class="table-dark">
                                <tr role="row">
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">Name</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">Phone</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">Address</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">Postal</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">City</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">Website</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in company_list.list" v-bind:key="item">
                                <td>
                                    {{item.name}}
                                </td>
                                <td>
                                    {{item.phone || '-'}}  
                                </td>
                                <td>
                                    {{item.address || '-'}}     
                                </td>
                                <td>
                                    {{item.postal_code || '-'}}
                                </td>
                                <td>
                                    {{item.city || '-'}}, {{item.province || '-'}}
                                </td>
                                <td>
                                    {{item.website || '-'}}
                                </td>
                                
                                <td style='text-align: right; position: relative;'>
                                    <button class="btn btn-primary btn-sm" @click='edit_item(item)' v-if='item.company_id > 0'><i class="zwicon-pencil"></i>&nbsp;Edit</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                            
                            <div class='row mt-2' v-if='company_list.pagination !== undefined'>
                                <div class='col-md-6 pt-2'>Showing {{(company_list.pagination.per_page*company_list.pagination.page)+1}} to {{pagination_max}} of {{company_list.pagination.total_items}} companies.</div>
                                <div class='col-md-6'>
                                    <div class="ml-auto" v-if='pages.length > 1'>
                                        <Pagination @set_page="view_page" :current_page='company_list.pagination.page' :pages='pages' />
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>

	import { mapGetters } from "vuex";
	import store from "@/store";
	import router from "@/router";

	import { LOAD_COMPANY_LIST } from "@/store/actions.type";

    import AddItem from '@/views/Admin/Modals/AddItem';
    import EditItem from '@/views/Admin/Modals/EditItem';

	import Pagination from '@/components/General/Pagination';

	export default {
		name: 'Inventory',
		components: {
            AddItem,
            EditItem,
            Pagination
		},
		props: {
			error: {
				type: String,
				default: "" 
			},
			success: {
				type: String,
				default: ""
			}
		},
        data(){
            return {
                filter_list: false,
                active_item: '',
                show_view: false,
                add_item_modal: false,
                edit_item_modal: false,
                show_delete: false,
                show_filter_modal: false,
                success_msg: '',
				filter_error: '',
                filter: {},
            }
        },
        beforeMount() {
            this.reset_filter_form();
			this.load_list(function(){
                console.log('try to load')
			}, function(){
                console.log('load error')
				router.replace({ name: 'inventory', params: { error: "An error occured while attempting to load inventory list." }});
			});
		},
		computed: {
            pagination_max(){
				var page_max = (this.company_list.pagination.per_page*(this.company_list.pagination.page+1));
				if(page_max > this.company_list.pagination.total_items)  page_max = this.company_list.pagination.total_items;
				return page_max;
			},
			pages(){
				let page_list = [];
				for(var i = 0;i < Math.ceil(this.company_list.pagination.total_items / this.company_list.pagination.per_page);i++){
					page_list.push((i*1)+1);
				}
				return page_list;
			},
			...mapGetters(['company_list', 'user', 'loading','size_list','category_list','connector_list','description_list'])
		},
        methods: {
            reset_filter_form(){
				this.filter = {
					page: 0,
					search: '',
					sort_by: 'id',
					sort_dir: 'DESC'
				};
			},
			open_filters(){
				this.show_filter_modal = true;
			},
			close_filters(){
				this.show_filter_modal = false;
			},
            load_list(success_fn, failure_fn){
				this.list_loading = true;
				this.filter_error = "";
				console.log('load list')
				store.dispatch(LOAD_COMPANY_LIST, this.filter).then(data => {
					success_fn(data);
				}).catch(message => {
					failure_fn(message);
				}).finally(() => {
					this.list_loading = false;
				});
			},
            view_page(page){
				this.filter.page = page;
				this.load_list(this.close_filters, function(){
					this.success_msg = '';
					this.filter_error = '';
				});
			},
            add_item(){
                this.add_item_modal = true;
            },
            item_added(){
                this.add_item_modal = false;
                this.load_list();
            },
            edit_item(item){
                this.action_item = item;
                this.edit_item_modal = true;
            },
            item_edited(){
                this.edit_item_modal = false;
                this.load_list();
            }
        }
	}
</script>